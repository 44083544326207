<template>
    <div>
        <div class="card card-custom gutter-b bg-white border-0">
            <div class="card-header border-0 align-items-center">
                <h3 class="card-label mb-0 font-weight-bold text-body">
                    Advance Information
                </h3>
            </div>
            <div class="card-body">
                <form>
                    <div class="form-group row">


                        <div class="col-md-3">
                            <label>&nbsp;</label>
                            <div class=" switch-h d-flex justify-content-between align-items-center border p-2 mb-3">
                                <h4 class="font-size-h4 text-dark mb-0">Is Active?</h4>
                                <div
                                    class=" custom-control switch custom-switch-info custom-switch custom-control-inline mr-0">
                                    <input type="checkbox" class="custom-control-input" id="customSwitchcolor445"
                                        :value="product_status" v-model="product_status"
                                        v-on:input="setProductStatus($event.target.value)" />
                                    <label class="custom-control-label mr-1" for="customSwitchcolor445">
                                    </label>
                                </div>
                            </div>
                            <small class="form-text text-danger" v-if="errors.has('product_status')"
                                v-text="errors.get('product_status')"></small>
                        </div>

                        <div class="col-md-3">
                            <label>&nbsp;</label>
                            <div class=" switch-h d-flex justify-content-between align-items-center border p-2 mb-3">
                                <h4 class="font-size-h4 text-dark mb-0">Is Show In home ?</h4>
                                <div
                                    class=" custom-control switch custom-switch-info custom-switch custom-control-inline mr-0">
                                    <input type="checkbox" class="custom-control-input" id="show_in_home"
                                        :value="is_show_in_home" v-model="is_show_in_home"
                                        v-on:input="setIsShowHome($event.target.value)" />
                                    <label class="custom-control-label mr-1" for="show_in_home">
                                    </label>
                                </div>
                            </div>
                            <small class="form-text text-danger" v-if="errors.has('is_show_in_home')"
                                v-text="errors.get('is_show_in_home')"></small>
                        </div>






                        <div class="col-md-3">
                            <label>&nbsp;</label>
                            <div class=" switch-h d-flex justify-content-between align-items-center border p-2 mb-3">
                                <h4 class="font-size-h4 text-dark mb-0">Is Point</h4>
                                <div
                                    class=" custom-control switch custom-switch-info custom-switch custom-control-inline mr-0">
                                    <input type="checkbox" class="custom-control-input" id="points" :value="is_points"
                                        v-model="is_points" v-on:input="setIsPoints($event.target.value)" />
                                    <label class="custom-control-label mr-1" for="points">
                                    </label>
                                </div>
                            </div>
                            <small class="form-text text-danger" v-if="errors.has('is_points')"
                                v-text="errors.get('is_points')"></small>
                        </div>


                        <div class="col-md-3">
                            <label>&nbsp;</label>
                            <div class=" switch-h d-flex justify-content-between align-items-center border p-2 mb-3">
                                <h4 class="font-size-h4 text-dark mb-0">Is Feature</h4>
                                <div
                                    class=" custom-control switch custom-switch-info custom-switch custom-control-inline mr-0">
                                    <input type="checkbox" class="custom-control-input" id="features" :value="is_featured"
                                        v-model="is_featured" v-on:input="setIsFeatured($event.target.value)" />
                                    <label class="custom-control-label mr-1" for="features">
                                    </label>
                                </div>
                            </div>
                            <small class="form-text text-danger" v-if="errors.has('is_featured')"
                                v-text="errors.get('is_featured')"></small>
                        </div>




                        <div class="col-md-3">
                            <label>&nbsp;</label>
                            <div class=" switch-h d-flex justify-content-between align-items-center border p-2 mb-3">
                                <h4 class="font-size-h4 text-dark mb-0">Is Atimer</h4>
                                <div
                                    class=" custom-control switch custom-switch-info custom-switch custom-control-inline mr-0">
                                    <input type="checkbox" class="custom-control-input" id="timer" :value="is_atimer"
                                        v-model="is_atimer" v-on:input="setIsTimer($event.target.value)" />
                                    <label class="custom-control-label mr-1" for="timer">
                                    </label>
                                </div>
                            </div>
                            <small class="form-text text-danger" v-if="errors.has('is_atimer')"
                                v-text="errors.get('is_atimer')"></small>
                        </div>



                        <div class="col-md-3">
                            <label>&nbsp;</label>
                            <div class=" switch-h d-flex justify-content-between align-items-center border p-2 mb-3">
                                <h4 class="font-size-h4 text-dark mb-0">Is 50-80 OFF</h4>
                                <div
                                    class=" custom-control switch custom-switch-info custom-switch custom-control-inline mr-0">
                                    <input type="checkbox" class="custom-control-input" id="fifty"
                                        :value="is_afifty_to_eighty" v-model="is_afifty_to_eighty"
                                        v-on:input="setIsFiftyToEighty($event.target.value)" />
                                    <label class="custom-control-label mr-1" for="fifty">
                                    </label>
                                </div>
                            </div>
                            <small class="form-text text-danger" v-if="errors.has('is_afifty_to_eighty')"
                                v-text="errors.get('is_afifty_to_eighty')"></small>
                        </div>











                        <div class="col-md-6">
                            <label>Product Type</label>
                            <fieldset class="form-group mb-3">
                                <select @change="setProductType($event.target.value)"
                                    class=" single-select w-100 mb-3 categories-select ms-offscreen" v-model="product_type">
                                    <option value="" disabled>Select Product Type</option>
                                    <option value="simple" :disabled="product_type == 'variable' && edit">
                                        Simple
                                    </option>
                                    <option value="variable" :disabled="product_type == 'simple' && edit">
                                        Variable
                                    </option>
                                </select>
                                <small class="form-text text-danger" v-if="errors.has('product_type')"
                                    v-text="errors.get('product_type')"></small>
                            </fieldset>
                        </div>










                        <div class="col-md-6">
                            <label>Units</label>
                            <fieldset class="form-group mb-3">
                                <select @change="setUnit($event.target.value)" class="
                                    single-select
                                    w-100
                                    mb-3
                                    categories-select
                                    ms-offscreen
                                " v-model="unit_id">
                                    <option value="">Select Unit</option>
                                    <option v-for="unit in units" v-bind:value="unit.id">
                                        {{
                                            unit.detail == null
                                            ? ""
                                            : unit.detail[0]
                                                ? unit.detail[0].name
                                                : ""
                                        }}
                                    </option>
                                </select>
                            </fieldset>
                            <small class="form-text text-danger" v-if="errors.has('unit_id')"
                                v-text="errors.get('unit_id')"></small>
                        </div>
                        <div class="col-md-6">
                            <label>Brands</label>
                            <fieldset class="form-group mb-3">
                                <select @change="setBrand($event.target.value)" class="
                                        single-select
                                        w-100
                                        mb-3
                                        categories-select
                                        ms-offscreen
                                    " v-model="brand_id">
                                    <option value="">Select Brand</option>
                                    <option v-for="brand in brands" v-bind:value="brand.brand_id">
                                        {{ brand.brand_name }}
                                    </option>
                                </select>
                            </fieldset>
                            <small class="form-text text-danger" v-if="errors.has('brand_id')"
                                v-text="errors.get('brand_id')"></small>
                        </div>
                        <div class="col-md-6">
                            <label>Product Weight</label>
                            <fieldset class="form-group mb-3">
                                <input type="text" id="weight" class="form-control round bg-transparent text-dark"
                                    placeholder="Enter Weight" v-on:input="setProductWeight($event.target.value)"
                                    v-model="product_weight" />
                            </fieldset>
                            <small class="form-text text-danger" v-if="errors.has('product_weight')"
                                v-text="errors.get('product_weight')"></small>
                        </div>
                        <div class="col-md-6">
                            <label>Price</label>
                            <fieldset class="form-group mb-3">
                                <input type="number" class="form-control round bg-transparent text-dark"
                                    placeholder="Enter Price" v-on:input="setPrice($event.target.value)" v-model="price" />
                            </fieldset>
                            <small class="form-text text-danger" v-if="errors.has('price')"
                                v-text="errors.get('price')"></small>
                        </div>
                        <div class="col-md-6">
                            <label>Discount Price</label>
                            <fieldset class="form-group mb-3">
                                <input type="number" class="form-control round bg-transparent text-dark"
                                    placeholder="Enter Discount Price" v-on:input="setDiscountPrice($event.target.value)"
                                    v-model="discount" />
                            </fieldset>
                            <small class="form-text text-danger" v-if="errors.has('discount')"
                                v-text="errors.get('discount')"></small>
                        </div>
                        <div class="col-md-3">
                            <label>Minimum Order </label>
                            <fieldset class="form-group mb-3">
                                <input type="text" id="type" class="form-control round bg-transparent text-dark"
                                    placeholder="Enter Minimum Order" v-on:input="setProductMinOrder($event.target.value)"
                                    v-model="product_min_order" />
                            </fieldset>
                            <small class="form-text text-danger" v-if="errors.has('product_min_order')"
                                v-text="errors.get('product_min_order')"></small>
                        </div>
                        <div class="col-md-3">
                            <label>Maximum Order</label>
                            <fieldset class="form-group mb-3">
                                <input type="text" id="type-max" class="form-control round bg-transparent text-dark"
                                    placeholder="Enter Maximum Order" v-on:input="setProductMaxOrder($event.target.value)"
                                    v-model="product_max_order" />
                            </fieldset>
                            <small class="form-text text-danger" v-if="errors.has('product_max_order')"
                                v-text="errors.get('product_max_order')"></small>
                        </div>

                        <div class="col-md-6">
                            <label>SKU</label>
                            <fieldset class="form-group mb-3">
                                <input type="text" id="type-max" class="form-control round bg-transparent text-dark"
                                    placeholder="Enter Sku" v-on:input="setProductsku($event.target.value)" v-model="sku" />
                            </fieldset>
                            <small class="form-text text-danger" v-if="errors.has('sku')"
                                v-text="errors.get('sku')"></small>
                        </div>
                        <div class="col-md-6">
                            <label>Product option</label>
                            <fieldset class="form-group mb-3">
                                <select @change="setProductOption($event.target.value)" class="
                                    single-select
                                    w-100
                                    mb-3
                                    categories-select
                                    ms-offscreen
                                " v-model="product_option">
                                    <option value="" disabled>Select Product Type</option>
                                    <option value="simple" :disabled="product_option == 'benefit' && edit">
                                        Simple
                                    </option>
                                    <option value="benefit" :disabled="product_option == 'simple' && edit">
                                        Benefit
                                    </option>

                                    <option value="MATERIALS & CONSTRUCTION	"
                                        :disabled="product_option == 'simple' && edit">
                                        MATERIALS & CONSTRUCTION
                                    </option>

                                </select>
                                <small class="form-text text-danger" v-if="errors.has('product_option')"
                                    v-text="errors.get('product_option')"></small>
                            </fieldset>
                        </div>


                        <div class="col-md-6" v-if="product_type == 'variable'">
                            <label>Attributes</label>
                            <fieldset class="form-group mb-3 d-flex">
                                <select class="
                                    single-select
                                    w-100
                                    mb-3
                                    categories-select
                                    ms-offscreen
                                " v-model="attribute">
                                    <option value="">Select Attributes</option>
                                    <option v-for="attributes in attributes" v-bind:value="attributes.attribute_id">
                                        {{
                                            attributes.detail == null
                                            ? ""
                                            : attributes.detail[0]
                                                ? attributes.detail[0].name
                                                : ""
                                        }}
                                    </option>
                                </select>
                                <button type="button" @click.prevent="getVariation()"
                                    class="btn-secondary btn ml-2 white pt-2 pb-1" >
                                    ADD
                                </button>
                                <button type="button" @click.prevent="unsetVariationData()"
                                    class="btn-secondary btn ml-2 white pt-2 pb-1" 
                                    v-show="displayClearBtn">
                                    Remove
                                </button>
                            </fieldset>
                            <small class="form-text text-danger" v-if="errors.has('attributes')"
                                v-text="errors.get('attributes')"></small>
                        </div>
                        <!-- opinion test -->
                        <div class="col-md-6">
                            <label>Opinions</label>
                            <fieldset class="form-group mb-3 d-flex">
                                <select class="
                                        single-select
                                        w-100
                                        mb-3
                                        categories-select
                                        ms-offscreen
                                    " v-model="opinion">
                                    <option value="">Select Opinion</option>
                                    <option v-for="opinions in opinions" v-bind:value="opinions.opinion_id">
                                        {{
                                            opinions.detail == null
                                            ? ""
                                            : opinions.detail[0]
                                                ? opinions.detail[0].name
                                                : ""
                                        }}
                                    </option>
                                </select>
                                <button type="button" @click.prevent="getBenefit()"
                                    class="btn-secondary btn ml-2 white pt-2 pb-1">
                                    ADD
                                </button>
                                <button type="button" @click.prevent="unsetBenefitData()"
                                    class="btn-secondary btn ml-2 white pt-2 pb-1" :disabled="editChild == true"
                                    v-show="displayClearBtns">
                                    Remove
                                </button>
                            </fieldset>
                            <small class="form-text text-danger" v-if="errors.has('opinions')"
                                v-text="errors.get('opinions')"></small>
                        </div>
                        <!-- end opinion test -->
                        <div class="col-md-6" v-if="product_type == 'variable'"></div>
                        <div class="col-md-6" v-if="product_option == 'benefit'"></div>

                        <!-- benefit test -->
                        <template v-for="(benefit, index) in benefits">
                            <div class="col-md-6 mb-3" v-if="benefit.show_benefit">
                                <label>{{
                                    benefit.detail == null ? "" : benefit.detail[0].name
                                }}</label>
                                <fieldset class="form-group mb-3 d-flex">
                                    <select class="
                                            single-select
                                            w-100
                                            mb-3
                                            categories-select
                                            ms-offscreen
                                            " multiple>
                                        <option value="" selected disabled>
                                            Select here
                                            {{
                                                benefit.detail == null ? "" : benefit.detail[0].name
                                            }}
                                        </option>
                                        <option
                                            v-bind:selected="benefitData['benefit'] ? benefitData['benefit'].includes(benefitDetails.id) : false"
                                            @click="updateBenefits(benefitData['benefit'], benefitDetails.id); setBenefits('benefit', benefit.opinion_id, benefitData['benefit'])"
                                            v-for="benefitDetails in benefit.benefits" v-bind:value="benefitDetails.id"
                                            :set="(allBenefits[benefitDetails.id] =
                                                benefitDetails.detail == null
                                                    ? ''
                                                    : benefitDetails.detail[0].name)
                                                ">
                                            {{
                                                benefitDetails.detail == null
                                                ? ""
                                                : benefitDetails.detail[0].name
                                            }}
                                        </option>
                                    </select>
                                </fieldset>
                            </div>

                            <div class="col-12" style="margin-bottom: 5rem" v-if="benefit.show_editor">
                                <label>{{
                                    benefit.detail == null ? "" : benefit.detail[0].name
                                }}</label>
                                <fieldset id="parent" class="form-group mb-3 d-flex">

                                    <!-- <VueEditor v-model="editorValues[index]" @input="
                                        setEditor(
                                            editorValues[index],
                                            benefit.opinion_id
                                        )
                                        " /> -->
                                    <quillEditor   v-model="editorValues[index]"
                                        @input="
                                            setEditor(
                                                editorValues[index],
                                                benefit.opinion_id
                                            )
                                            " />

                                    <!-- <ckeditor
                                        :editor="editor"
                                        v-model="editorValues[index]"
                                        :config="editorConfig"
                                        @input="
                                        setEditor(
                                            editorValues[index],
                                            benefit.opinion_id
                                        )"
                                        ></ckeditor> -->
                                </fieldset>
                            </div>




 <div class="col-12" style="margin-bottom: 5rem" v-if="benefit.show_gallary_description"> 
                               
                        <template v-if="benefit.show_gallary_description">
                            <label>{{
                                    benefit.detail == null ? "Show Gallary Description" : benefit.detail[0].name
                                }}</label>

                             
  <div>
    <div class="row">
<div class="col-md-6">
    <quillEditor   v-model="DataGallary_description.title"
            @input="showGallaryDescription"/>
    </div>

    <div class="col-md-6">
        <td>
            <input type="color" v-model="DataGallary_description.color"  @change="showGallaryDescription">
          </td>
</div>
        </div>
    <table class="styled-table">
      <thead>
        <tr>
          <th>Title</th>
          <th>Description</th>
          <th>Image</th>
          <th>Video</th>
          <th>Show Media</th>
          <th>Align Media</th>
          <th>Action</th> <!-- New column for Add/Remove buttons -->
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in DataGallary_description.Gallary_description" :key="index">
          <td>
            <quillEditor   v-model="item.title"
                                      
                                           @input="showGallaryDescription"
                                         />
            
            
            
                    </td>
          <td>
            <quillEditor   v-model="item.description"
            @input="showGallaryDescription"/>
            
            </td>
                <td>
            <button type="button" class="btn btn-primary" @click="toggleImageSelect(item.image, index,'gallary_description')">
                Upload Media
            </button>
            <img v-if="DataGallary_description.Gallary_description[index]['image'] !== '' && DataGallary_description.Gallary_description[index]['image'] !== null"
                :src="DataGallary_description.Gallary_description[index]['image']"
                style="width: 100px; height: 100px" />
            <input type="hidden" v-model="DataGallary_description.Gallary_description[index]['image']" @input="updateHiddenImage($event, index)" required>
        </td>
 

          <td><input type="text" v-model="item.video" @input="showGallaryDescription" required></td>
          <td>
            <label><input type="radio" v-model="item.show_media" value="image" @input="showGallaryDescription"> Image</label>
            <label><input type="radio" v-model="item.show_media" value="video" @input="showGallaryDescription"> Video</label>
          </td>
          <td>
            <label><input type="radio" v-model="item.media_align" value="left" @input="showGallaryDescription"> Left</label>
            <label><input type="radio" v-model="item.media_align" value="right" @input="showGallaryDescription"> Right</label>
          </td>
         
          <td>
            <button @click.prevent="removeRow(index)" v-if="DataGallary_description.Gallary_description.length > 1" class="btn-remove"><i class="fas fa-minus-circle"></i> </button> <!-- Button to remove row -->
            <button @click.prevent="addRow(index)" v-if="index === DataGallary_description.Gallary_description.length - 1" class="btn-add"><i class="fas fa-plus-circle"></i> </button> <!-- Button to add row -->
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>




                            </div>



                            <div class="col-12" style="margin-bottom: 5rem" v-if="benefit.full_width_gallary"> 
                               
                               <template v-if="benefit.full_width_gallary">
                                   <label>{{
                                           benefit.detail == null ? "Full Width Gallary" : benefit.detail[0].name
                                           
                                       }}</label>
                                       
         <div>
        
           <table class="styled-table">
             <thead>
               <tr>
          <th>Title</th>
          <th>Description</th>
          <th>Image</th>
          <th>Video</th>
          <th>Show Media</th>
          <th>Action</th>
               </tr>
             </thead>
             <tbody>
               <tr v-for="(item, index) in full_width_gallary" :key="index">
                 <td>
                   <quillEditor   v-model="item.title"
                                             
                                                  @input="FullWidthGallary"
                                                />
                   
                   
                   
                           </td>
                 <td>
                   <quillEditor   v-model="item.description"
                   @input="FullWidthGallary"/>
                   
                   </td>
                       <td>
                   <button type="button" class="btn btn-primary" @click="toggleImageSelect(item.image, index,'fullwidth')">
                       Upload Media
                   </button>
                   <img v-if="full_width_gallary[index]['image'] !== '' && full_width_gallary[index]['image'] !== null"
                       :src="full_width_gallary[index]['image']"
                       style="width: 100px; height: 100px" />
                   <input type="hidden" v-model="full_width_gallary[index]['image']" @input="updateHiddenImage($event, index)" required>
               </td>
        
       
                 <td><input type="text" v-model="item.video" @input="FullWidthGallary" required></td>
                 <td>
                   <label><input type="radio" v-model="item.show_media" value="image" @input="FullWidthGallary"> Image</label>
                   <label><input type="radio" v-model="item.show_media" value="video" @input="FullWidthGallary"> Video</label>
                 </td>
                 
                
                 <td>
                   <button @click.prevent="removeRowFullWidth(index)" v-if="full_width_gallary.length > 1" class="btn-remove"><i class="fas fa-minus-circle"></i> </button> <!-- Button to remove row -->
                   <button @click.prevent="addRowFullWidth(index)" v-if="index === full_width_gallary.length - 1" class="btn-add"><i class="fas fa-plus-circle"></i> </button> <!-- Button to add row -->
                 </td>
               </tr>
             </tbody>
           </table>
         </div>
       </template>
       
       
       
       
                                   </div>
       


































                        </template>
                        <!-- end benefit test -->
                        <template v-if="product_type == 'variable'" v-for="(variation, index) in variations">
                            <div class="col-md-6">
                                <label>{{
                                    variation.detail == null ? "" : variation.detail[0].name
                                }}</label>
                                <fieldset class="form-group mb-3 d-flex">
                                    <select class="
                                        single-select
                                        w-100
                                        mb-3
                                        categories-select
                                        ms-offscreen
                                        " v-model="variationData['variation_' + variation.attribute_id]" multiple
                                        @change="setVariations('variation_' + variation.attribute_id)"
                                        >
                                         <option value="" selected disabled>
                                            Select
                                            {{
                                                variation.detail == null ? "" : variation.detail[0].name
                                            }}
                                        </option>
                                        <option v-for="variationDetail in variation.variations"
                                            v-bind:value="variationDetail.id" :set="(allVariations[variationDetail.id] =
                                                variationDetail.detail == null
                                                    ? ''
                                                    : variationDetail.detail[0].name)
                                                ">
                                            {{
                                                variationDetail.detail == null
                                                ? ""
                                                : variationDetail.detail[0].name
                                            }}
                                        </option>
                                    </select>
                                </fieldset>
                            </div>
                            <div class="col-md-6"></div>
                        </template>

                        <template v-if="product_type == 'variable'"
                        
                            v-for="(combination_detail, index) in combinationDetail">
                            <template v-if="index == 0">
                                <div class="col-md-3">Variant</div>
                                <div class="col-md-3"> Price</div>
                                <div class="col-md-3">SKU</div>
                                <div class="col-md-3">Image</div>
                            </template>

                            <div class="col-md-3 mt-3">
                                {{ combination_detail.variation_name }}
                            </div>
                            <div class="col-md-3 mt-3">
                                <input type="text" :name="combination_detail.price"
                                    v-model="combinationPrice[combination_detail.price]" v-on:input="
                                        setCombinationPrice(
                                            combination_detail.price,
                                            combinationPrice[combination_detail.price]
                                        )
                                        " />
                            </div>
                            <div class="col-md-3 mt-3">
                                <input type="text" :name="combination_detail.sku"
                                    v-model="combinationSku[combination_detail.sku]" v-on:input="
                                        setCombinationSku(
                                            combination_detail.sku,
                                            $event.target.value
                                        )
                                        " />
                            </div>
                            <div class="col-md-3 mt-3">
                                <button type="button" class="btn btn-primary"
                                    @click="toggleImageSelect(combination_detail.gallary)">
                                    Upload Media
                                </button>
                                <img v-if="combinationGalleryPath[combination_detail.gallary] != '' &&
                                    combinationGalleryPath[combination_detail.gallary] != null
                                    " :src="combinationGalleryPath[combination_detail.gallary]"
                                    style="width: 100px; height: 100px" />
                            </div>
                        </template>
                        <!-- test one -->
                        <!-- tab test -->
                        <template>
                            <div class="container">
                                <ul class="nav nav-tabs">
                                    <li class="nav-item" v-for="(fusion_detail, index) in fusionDetail" :key="index">
                                        <a class="nav-link" :class="{ active: activeTab === fusion_detail }"
                                            @click="activeTab = fusion_detail; log(fusionsData, fusionDetail)">{{
                                                fusion_detail.benefit_name }}</a>
                                    </li>
                                </ul>

                                <div class="tab-content" v-if="fusionDetail">
                                    <div class="tab-pane" :class="{ active: activeTab === fusion_detail }"
                                        v-for="(fusion_detail, index) in fusionDetail" :key="index">
                                        <div v-if="activeTab === fusion_detail || (log(activeTab, fusion_detail))">
                                            <div class="card-body">
                                                <div class="container">
                                                    <div class="row">
                                                        <div class="col-md-6">
                                                            <h4 class="nav nav-pills lang-tab">{{ fusion_detail.benefit_name
                                                            }}</h4>
                                                        </div>
                                                        <div class="col-md-3">
                                                            <label>Product Score </label>

                                                            <fieldset class="form-group mb-3">
                                                                <input type="number" :name="'fusion_detail.score_' + index"
                                                                    class="form-control round bg-transparent text-dark"
                                                                    placeholder="Score"
                                                                    v-model="fusionsData[index]['score']" v-on:input="
                                                                        setFusion(
                                                                            fusionsData[index]['score']
                                                                        )
                                                                        " />
                                                            </fieldset>
                                                            <small class="form-text text-danger"
                                                                v-if="errors.has('fusion.score_' + index)"
                                                                v-text="errors.get('fusion.score_' + index)"></small>
                                                        </div>
                                                        <div class="col-md-3">
                                                            <label>Product Note </label>
                                                            <fieldset class="form-group mb-3">
                                                                <input type="text" :name="'fusion.note_' + index"
                                                                    class="form-control round bg-transparent text-dark"
                                                                    placeholder="Note" v-model="fusionsData[index]['note']"
                                                                    v-on:input="
                                                                        setFusion(
                                                                            fusionsData[index]['note']
                                                                        )
                                                                        " />

                                                            </fieldset>
                                                            <small class="form-text text-danger"
                                                                v-if="errors.has('fusion.note_' + index)"
                                                                v-text="errors.get('fusion.note_' + index)"></small>

                                                        </div>
                                                        <h6 class="text-body">Description</h6>
                                                        <fieldset class="form-group col-md-12">
                                                            <textarea id="label-textarea"
                                                                :name="'fusion.description_' + index" rows="6"
                                                                v-model="fusionsData[index]['description']"
                                                                placeholder="Overview" spellcheck="false"
                                                                class="form-control" style="height: 70px" v-on:input="
                                                                    setFusion(
                                                                        fusionsData[index]['description']
                                                                    )
                                                                    "></textarea>
                                                            <small class="form-text text-danger"
                                                                v-if="errors.has('fusion.description_' + index)"
                                                                v-text="errors.get('fusion.description_' + index)"></small>
                                                            <small class="form-text text-danger"
                                                                v-if="errors.has('fusion.description_' + index)"
                                                                v-text="errors.get('fusion.description_' + index)"></small>
                                                        </fieldset>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-md-6">
                                                            <div class="row">


                                                            </div>
                                                            <div class="row">
                                                                <div class="col-12">
                                                                    <hr>
                                                                    <div class="col-md-6 mt-6">
                                                                        <button type="button" class="btn btn-primary"
                                                                            @click="toggleImageSelect('fusion.gallary_' + index, index,'fusion')">
                                                                            Upload Media
                                                                        </button>
                                                                        <img v-if="fusionsGalleryPath['fusion.gallary_' + index] != '' &&
                                                                            fusionsGalleryPath['fusion.gallary_' + index] != null
                                                                            "
                                                                            :src="fusionsGalleryPath['fusion.gallary_' + index]"
                                                                            style="width: 300px; height: 400px" />
                                                                    </div>
                                                                    <hr>
                                                                </div>
                                                                <div class="col-12">
                                                                    <hr>
                                                                    <div class="col-md-6 mt-6">
                                                                        <button type="button" class="btn btn-primary"
                                                                            @click="toggleIconSelect('fusion.icon_' + index, index)">
                                                                            Upload icon
                                                                        </button>
                                                                        <img v-if="fusionsGalleryPath['fusion.icon_' + index] != '' &&
                                                                            fusionsGalleryPath['fusion.icon_' + index] != null
                                                                            "
                                                                            :src="fusionsGalleryPath['fusion.icon_' + index]"
                                                                            style="width: 300px; height: 400px" />
                                                                    </div>
                                                                    <hr>
                                                                </div>

                                                            </div>

                                                        </div>
                                                        <div class="col-md-6">
                                                            <div class="col-md-12">
                                                                <label>Product Title1 </label>
                                                                <fieldset class="form-group mb-3">
                                                                    <input type="text"
                                                                        :name="'fusion_detail.heading1_' + index"
                                                                        class="form-control round bg-transparent text-dark"
                                                                        placeholder="product_score"
                                                                        v-model="fusionsData[index]['heading1']" v-on:input="
                                                                            setFusion(
                                                                                fusionsData[index]['heading1']
                                                                            )
                                                                            " />
                                                                </fieldset>
                                                                <small class="form-text text-danger"
                                                                    v-if="errors.has('fusion.heading1_' + index)"
                                                                    v-text="errors.get('fusion.heading1_' + index)"></small>
                                                            </div>
                                                            <div class="col-md-12">
                                                                <label>Product Title2 </label>
                                                                <fieldset class="form-group mb-3">
                                                                    <input type="text" :name="'fusion.heading2_' + index"
                                                                        class="form-control round bg-transparent text-dark"
                                                                        placeholder="product_score"
                                                                        v-model="fusionsData[index]['heading2']" v-on:input="
                                                                            setFusion(
                                                                                fusionsData[index]['heading2']
                                                                            )
                                                                            " />
                                                                </fieldset>
                                                                <small class="form-text text-danger"
                                                                    v-if="errors.has('fusion.heading2_' + index)"
                                                                    v-text="errors.get('fusion.heading2_' + index)"></small>
                                                            </div>

                                                            <h6 class="text-body">
                                                                Content
                                                            </h6>
                                                            <fieldset class="form-group col-md-12">
                                                                <textarea id="label-textarea"
                                                                    :name="'fusion_detail.body_' + index" rows="6"
                                                                    v-model="fusionsData[index]['body']"
                                                                    placeholder="Overview" spellcheck="false"
                                                                    class="form-control" style="height: 100px" v-on:input="
                                                                        setFusion(
                                                                            fusionsData[index]['body']
                                                                        )
                                                                        "></textarea>
                                                                <small class="form-text text-danger"
                                                                    v-if="errors.has('fusion.body_' + index)"
                                                                    v-text="errors.get('fusion.body_' + index)"></small>
                                                                <small class="form-text text-danger"
                                                                    v-if="errors.has('fusion.body_' + index)"
                                                                    v-text="errors.get('fusion.body_' + index)"></small>
                                                            </fieldset>

                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                        <!-- Tab content here -->

                                        <!-- Display tab content -->

                                    </div>
                                </div>
                            </div>
                        </template>

                    </div>
                </form>
            </div>
        </div>


        <div class="row">
            <div class="col-12 d-flex justify-content-end">
                <a data-toggle="pill" @click.prevent="setActive('info-tab')" :class="{ active: isActive('info-tab') }"
                    class="btn btn-dark swipe-to-top cta">Back</a>
                <hr>
                <a data-toggle="pill" href="#" class="btn btn-primary cta" @click.prevent="setActive('seo-tab')"
                    :class="{ active: isActive('seo-tab') }">Continue</a>
            </div>
        </div>
        <attach-image @toggleImageSelect="toggleImageSelect" :showModal="showModal" @setImage="setImage" />
        <attach-image @toggleImageSelect="toggleIconSelect" :showModal="showIconModal" @setImage="setIcon" />

    </div>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'

export default {
    components: {
        quillEditor
    },
    data() {
        return {
            csrf: document
                .querySelector('meta[name="csrf-token"]')
                .getAttribute("content"),
            editorOption:[],
            units: [],
            new_sku: [],
            brands: [],
            attributes: [],
            selectedBenefit: '',
            opinions: [],
            benefits: [],
            editorData: {},
            editorValues: [],
            variations: [],
            product_type: "",
            attribute: "",
            opinion: "",
            selectedAttribute: [],
            selectedOpinion: [],
            product_status: true,
            is_featured: true,
            is_atimer: true,
            is_afifty_to_eighty: true,
            is_show_in_home: true,
            is_points: true,
            unit_id: "",
            product_weight: "",
            brand_id: "",
            price: "",
            sku: "",
            product_option: "",
            discount: "",
            product_min_order: 1,
            product_max_order: 5,
            combinationPrice: {},
            combinationSku: {},
            combinationGallery: {},
            combinationGalleryPath: {},
            GallaryDescriptionImagePath:{},

            fusionScore: {},
            fusionNote: {},
            fusionDescription: {},
            fusionHeading1: {},
            fusionHeading2: {},
            fusionBody: {},
            fusionGallery: {},
            fusionsGalleryPath: {},
            variationData: {},
            benefitData: {},
            combinations: [],
            fusions: [],
            combinationDetail: [],
            fusionDetail: [],
            allVariations: [],
            allBenefits: [],
            showModal: false,
            showIconModal: false,
            currentSelectedGalleryName: "",
            currentSelectedGalleryIndex: "",
            currentSelectedGalleryType: "",
            currentSelectedIconName: "",
            currentSelectedIconIndex: "",
            lastSku: "",
            token: [],
            edit_combination_detail: [],
            edit_fusion_detail: [],
            editChild: false,
            displayClearBtn: 0,
            displayClearBtns: 0,
            edit_variation_name: [],
            edit_combinationPrice: [],
            edit_combinationSku: [],
            edit_combinationGalleryPath: [],
            edit_combinationGallery: [],
            edit_benefit_name: [],
            fusionsData: [],
            activeTab: null,
            fetchData: false ,
    DataGallary_description: 
        {
        title:'',
        color:'',
        Gallary_description: [{
          title: '',
          description: '',
          image: '',
          video: '',
          show_media: '',
          media_align: '',
        }]
    }
      ,
      full_width_gallary: [{
          title: '',
          description: '',
          image: '',
          video: '',
          show_media: '',
      }]

     
  
        };
    },

    countries: [
        { code: 'GB', name: 'Great Britain' },
        { code: 'US', name: 'United States' },
        { code: 'KZ', name: 'Kazakhstan' }
    ],
    selectedCountry: null,
    user: {
        address: {
            country: null
        }
    },
    methods: {

        changeCountry(event) {
            this.user.address.country = event.target.value
            this.selectedCountry = event.target.options[event.target.options.selectedIndex].text
        },
        fetchUnits() {
            this.$parent.$parent.loading = true;
            var token = localStorage.getItem("token");
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            var responseData = {};

            axios
                .get("/api/admin/unit?getAllData=1&getDetail=1", config)
                .then((res) => {
                    if (res.data.status == "Success") {
                        this.units = res.data.data;
                    }
                   //console.error(this.units);
                })
                .finally(() => (this.$parent.$parent.loading = false));
        },
        fetchAttributes() {
            this.$parent.$parent.loading = true;
            var token = localStorage.getItem("token");
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            var responseData = {};

            axios
                .get("/api/admin/attribute?getAllData=1&getDetail=1", config)
                .then((res) => {
                    if (res.data.status == "Success") {
                        this.attributes = res.data.data;
                    }
                })
                .finally(() => (this.$parent.$parent.loading = false));
        },
        // test opinion
        fetchOpinions() {
            this.$parent.$parent.loading = true;
            var token = localStorage.getItem("token");
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            var responseData = {};

            axios
                .get("/api/admin/opinion?getAllData=1&getDetail=1", config)
                .then((res) => {
                    if (res.data.status == "Success") {
                        this.opinions = res.data.data;
                    }
                })
                .finally(() => (this.$parent.$parent.loading = false));
        },
        // end opinion test
        fetchBrands() {
            this.$parent.$parent.loading = true;
            var token = localStorage.getItem("token");
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            var responseData = {};

            axios
                .get("/api/admin/brand?getAllData=1", config)
                .then((res) => {
                    if (res.data.status == "Success") {
                        this.brands = res.data.data;
                    }
                })
                .finally(() => (this.$parent.$parent.loading = false));
        },
        setUnit(value) {
            this.$emit("setUnitInChild", value);
        },
        setBrand(value) {
            this.$emit("setBrandInChild", value);
        },
        setProductWeight(value) {
            this.$emit("setProductWeightInChild", value);
        },
        setProductMinOrder(value) {
            this.$emit("setProductMinOrderInChild", value);
        },
        setProductMaxOrder(value) {
            this.$emit("setProductMaxOrderInChild", value);
        },

        setFusion(value) {
           
            this.$emit("setFusion", this.fusionsData);
        },

        setEditor(value, opinion_id) {
            this.editorData[opinion_id] = value;
            this.$emit("setEditor", this.editorData);
        },

        setProductsku(value) {
            this.$emit("setProductskuInChild", value);
        },
        setPrice(value) {
            this.$emit("setPriceInChild", value);
        },
        setDiscountPrice(value) {
            this.$emit("setDiscountPriceInChild", value);
        },
        setProductType(value) {
            this.$emit("setProductTypeInChild", value);
        },
        setProductOption(value) {
            this.$emit("setProductOptionInChild", value);
        },
        setProductStatus(value) {
            this.$emit("setProductStatusInChild", value);
        },
        setIsFeatured(value) {
            this.$emit("setIsFeaturedInChild", value);
        },
        setIsTimer(value) {
            this.$emit("setIsTimerInChild", value);
        },
        setIsFiftyToEighty(value) {
            this.$emit("setIsFiftyToEightyInChild", value);
        },
        setIsPoints(value) {
            this.$emit("setIsPointsInChild", value);
        },
        setIsShowHome(value) {
            this.$emit("setIsShowHomeInChild", value);
        },

        setAttributes(value) {
            this.$emit("setAttributesInChild", value);
        },

        setOpinions(value) {
            this.$emit("setOpinionsInChild", value);
        },
        isActive(value) {
            this.$emit("isActiveInChild", value);
        },
        setActive(value) {
            this.$emit("setActiveInChild", value);
        },
       
        setCombinationPrice(name, price) {
            var newprice = price != null ? price : this.price;
            if (this.combinationPrice[name] == null) {
                this.combinationPrice[name] = newprice;
            }
            this.$emit("setCombinationPriceInChild", name, newprice);
        },
        setCombinationSku(name, sku) {
            var newsku = sku != null ? sku : this.sku;
            this.$emit("setCombinationSkuInChild", name, newsku);
        },
        setCombinationGallery(name) {
            if (this.combinationGallery[name] == null) {
                this.combinationGallery[name] = 0;
            }
            this.$emit(
                "setCombinationGalleryInChild",
                name,
                this.combinationGallery[name]
            );
        },
        // fusion
        setFusionScore(name, score) {
            var newscore = score != null;
            if (this.fusionScore[name] == null) {
                this.fusionScore[name] = newscore;
            }
            this.$emit("setFusionScoreInChild", name, newscore);
        },
        setFusionNote(name, note) {
            var newnote = note != null;
            if (this.fusionNote[name] == null) {
                this.fusionNote[name] = newnote;
            }
            this.$emit("setFusionNoteeInChild", name, newnote);
        },
        setFusionDescription(name, description) {
            var newdescription = description != null;
            if (this.fusionDescription[name] == null) {
                this.fusionDescription[name] = newdescription;
            }
            this.$emit("setFusionDescriptionInChild", name, newdescription);
        },
        setFusionHeading1(name, heading1) {
            var newheading1 = heading1 != null;
            if (this.fusionHeading1[name] == null) {
                this.fusionHeading1[name] = newheading1;
            }
            this.$emit("setFusionHeading1Child", name, newheading1);
        },
        setFusionHeading2(name, heading2) {
            var newheading2 = heading2 != null;
            if (this.fusionHeading2[name] == null) {
                this.fusionHeading2[name] = newheading2;
            }
            this.$emit("setFusionHeading2InChild", name, newheading2);
        },
        setFusionBody(name, body) {
            var newbody = body != null;
            if (this.fusionBody[name] == null) {
                this.fusionBody[name] = newbody;
            }
            this.$emit("setFusionBodyInChild", name, newbody);
        },
        setFusionGallery(name) {
            if (this.fusionGallery[name] == null) {
                this.fusionGallery[name] = 0;
            }
            this.$emit(
                "setFusionGalleryInChild",
                name,
                this.fusionGallery[name]
            );
        },
        // end fusion
        unsetVariationData() {
            this.variations = [];
            this.variationData = {};
            this.selectedAttribute = [];
            this.combinationDetail = [];
            this.combinationPrice = {};
            this.combinationSku = {};
            this.combinationGallery = {};
            this.combinations = [];
            this.combinationGalleryPath = {};
            this.displayClearBtn = 0;
        },
        unsetBenefitData() {
            this.benefits = [];
            this.benefitData = {};
            this.selectedOpinion = [];
            this.fusionDetail = [];
            this.fusionScore = {};
            this.fusionNote = {};
            this.fusionDescription = {};
            this.fusionHeading1 = {};
            this.fusionHeading2 = {};
            this.fusionBody = {};
            this.fusionGallery = {};
            this.fusions = [];
            this.fusionsGalleryPath = {};
            this.displayClearBtns = 0;
        },
        setVariations(name) {
            if (this.edit == 0 && (this.price == "" || this.sku == "")) {
                this.$toaster.error('Price or Sku Field can"t be empty');
                return;
            }

            this.$emit("setVariationsInChild", name, this.variationData[name]);
            // Check variation is selected against every attribute
            var totalVariations = 0;

            for (var i = 0; i < this.selectedAttribute.length; i++) {
                // console.error(this.variationData[i]);
                if (
                    this.variationData["variation_" + this.selectedAttribute[i]].length >
                    0
                ) {
                    totalVariations = parseInt(totalVariations) + 1;
                }
            }

            if (this.selectedAttribute.length != totalVariations) {
                this.combinationDetail = [];
                return;
            }
            if (this.edit == 0) {
                this.displayClearBtn = 1;
            }

            // create product combinations
            this.combinationDetail = [];
            this.combinationPrice = {};
            this.combinationSku = {};
            this.combinationGallery = {};
            this.combinations = [];
            for (var i = 0; i < this.selectedAttribute.length; i++) {
                this.combinations.push(
                    this.variationData["variation_" + this.selectedAttribute[i]]
                );
            }

            this.makeCombinationData();
        },

        updateBenefits(arr, val){
            if(!arr){
                this.benefitData['benefit'] = [val]
                return
            }
            if(arr.includes(val)){
                arr.splice( arr.indexOf(val), 1 )
            }else{
                arr.push(val)
            }
        },

        setBenefits(name, opinion_id, benefit_ids, value = null, arrayValue = []) {
           


if(arrayValue.length==0){
    if (value == null) {
                benefit_ids.forEach(el => {
                    value = { 'score': '', 'note': '', 'description': '', 'heading1': '', 'heading2': '', 'body': '', 'benefit_id': el, 'opinion_id': opinion_id };
                })
            }
            benefit_ids = benefit_ids.filter(val => {
                return val != null
            })
            console.info( value )
            this.fusionsData.push(value);


}else{
    if (value == null) {
                arrayValue.forEach(el => {
                    value = { 'score': '', 'note': '', 'description': '', 'heading1': '', 'heading2': '', 'body': '', 'benefit_id': el, 'opinion_id': opinion_id };
                })
            }
            arrayValue = arrayValue.filter(val => {
                return val != null
            })
            console.info( value )
            this.fusionsData.push(value);

}

        


            this.$emit("setBenefitsInChild", name, this.benefitData[name]);
            // Check variation is selected against every attribute
            var totalBenefit = 0;

            for (var i = 0; i < this.selectedOpinion.length; i++) {
                // console.error(this.variationData[i]);
                if (
                    this.benefitData["benefit"].length >
                    0
                ) {
                    totalBenefit = parseInt(totalBenefit) + 1;
                }
            }

            if (this.selectedOpinion.length != totalBenefit) {
                this.fusionDetail = [];
                return;
            }
            if (this.edit == 0) {
                this.displayClearBtns = 1;
            }

            // create product combinations
            this.fusionDetail = [];
            this.fusionScore = {};
            this.fusionNote = {};
            this.fusionDescription = {};
            this.fusionHeading1 = {};
            this.fusionHeading2 = {};
            this.fusionBody = {};
            this.fusionGallery = {};
            this.fusions = [];
            this.fusions.push(
                this.benefitData["benefit"]
            );

            this.makeFusionData(arrayValue);

        },

        makeCombinationData() {
            var res = this.cartesian(this.combinations);
            //  var res = this.combinations;
            var new_sku, sku_no;
            new_sku = this.lastSku;

            for (var i = 0; i < res.length; i++) {

                var arr = {};
                var price = "combination_price_";
                var sku = "combination_sku_";
                var gallary = "combination_gallary_";
                var variation_name = "";
                for (var j = 0; j < res[i].length; j++) {
                    if (this.allVariations[res[i][j]] == null) {
                        continue;
                    }
                    if (res[i].length - 1 == j) {
                        if (!this.edit) {
                            arr.new_sku = this.sku + "-" + (i + 1);
                            arr.price = this.price;
                        }
                        variation_name +=
                            this.allVariations[res[i][j]] == null
                                ? ""
                                : this.allVariations[res[i][j]];
                        arr.variation_name = variation_name;

                        arr.price = price + res[i][j];
                        arr.sku = sku + res[i][j];
                        arr.gallary = gallary + res[i][j];
                        if (!this.edit) {
                            this.setCombinationPrice(arr.price, null);
                            this.combinationSku[arr.sku] = arr.new_sku;
                            this.setCombinationSku(arr.sku, null);
                        }
                    } else {
                        variation_name +=
                            this.allVariations[res[i][j]] == null
                                ? ""
                                : this.allVariations[res[i][j]] + " - ";

                        price = price + res[i][j] + "_";
                        sku = sku + res[i][j] + "_";
                        gallary = gallary + res[i][j] + "_";
                    }
                }
                if (arr.hasOwnProperty("price") != false) {
                    this.combinationDetail.push(arr);
                }
            }
            // console.error(this.edit_combination_detail.length);




            if (
                this.combinationDetail.length > 0 &&
                this.edit_combination_detail.length > 0
            ) {



                for (var i = 0; i < res.length; i++) {
                    var variation_id = [];
                    var gallary_name = "combination_gallary_";
                    var price_name = "combination_price_";
                    var sku_name = "combination_sku_";
                    for (var j = 0; j < res[i].length; j++) {

                        variation_id.push(parseInt(res[i][j]));





                        if (res[i].length - 1 == j) {
                            gallary_name = gallary_name + res[i][j];
                            price_name = price_name + res[i][j];
                            sku_name = sku_name + res[i][j];


                          //  alert(gallary_name)

                       var combination_detail = this.edit_combination_detail.length
                            for (var z = 0; z < combination_detail ; z++) {
                                var is_combination = [];
                                var edit_variation_id = [];
                                for (
                                    var jj = 0;
                                    jj < this.edit_combination_detail[z].combination.length;
                                    jj++
                                ) {
                                    // console.error(variation_id.indexOf(
                                    //     this.edit_combination_detail[z].combination[jj]
                                    //         .variation_id
                                    // ), variation_id, parseInt(this.edit_combination_detail[z].combination[jj]
                                    //     .variation_id), "edit variation id")
                                    if (
                                        variation_id.indexOf(
                                            parseInt(this.edit_combination_detail[z].combination[jj]
                                                .variation_id)
                                        ) !== -1
                                    ) {
                                        is_combination.push(1);
                                    } else {
                                        is_combination.push(0);
                                    }
                                }
                                if (is_combination.indexOf(0) == -1) {

                                  //  console.error(price_name + ' => ' + this.edit_combination_detail[z].price);
                                    this.combinationPrice[price_name] =
                                        this.edit_combination_detail[z].price;
                                    this.setCombinationPrice(
                                        price_name,
                                        this.edit_combination_detail[z].price
                                    );
                                    this.combinationSku[sku_name] =
                                        this.edit_combination_detail[z].sku;

                                    this.setCombinationSku(
                                        sku_name,
                                        this.edit_combination_detail[z].sku
                                    );
                                    this.combinationGalleryPath[gallary_name] =
                                        "/gallary/" +
                                        this.edit_combination_detail[z].gallary.gallary_name;

                                    this.combinationGallery[gallary_name] =
                                        this.edit_combination_detail[z].gallary.id;
                                    this.setCombinationGallery(gallary_name);




                                }
                         }
                        } else {
                            price_name = price_name + res[i][j] + "_";
                            sku_name = sku_name + res[i][j] + "_";
                             gallary_name = gallary_name + res[i][j] + "_";
                        }
                    }
                 }
             }
        },

        log(...args){
            console.log(args)
        },
        // fusion

        makeFusionData(arrayValue = []) {
            // var res = this.fusions;
            if (arrayValue.length > 0) {
                var res = arrayValue;
            } else {
                var res = this.cartesian(this.fusions);
            }

            for (var i = 0; i < res.length; i++) {
                var arr = {};
                var score = "fusion_score_";    // Change "price" to "score"
                var note = "fusion_note_";     // Change "sku" to "note"
                var description = "fusion_description_";
                var heading1 = "fusion_heading1_";
                var heading2 = "fusion_heading2_";
                var body = "fusion_body_";
                var gallary = "fusion_gallary_";
                var benefit_name = "";
                if (arrayValue.length > 0) {
                    arr.benefit_name = res[i].benefit.name;
                }
                for (var j = 0; j < res[i].length; j++) {
                    if (this.allBenefits[res[i][j]] == null) {
                        continue;
                    }
                    if (res[i].length - 1 == j) {

                        benefit_name +=
                            this.allBenefits[res[i][j]] == null
                                ? ""
                                : this.allBenefits[res[i][j]];


                        if (arrayValue.length == 0) {
                            arr.benefit_name = benefit_name;
                        }
                    }
                    score += this.allBenefits[res[i][j]] == null ? "" : this.allBenefits[res[i][j]] + " - ";
                    note += "combination_note_" + res[i][j] + "_";
                    description += "combination_description_" + res[i][j] + "_";
                    heading1 += "combination_heading1_" + res[i][j] + "_";
                    heading2 += "combination_heading2_" + res[i][j] + "_";
                    body += "combination_body_" + res[i][j] + "_";
                }

                this.fusionDetail.push(arr);
            }

            // ... Rest of the code to handle edits, if necessary
        },

        // end fusion

        formatNumberLength(num, length) {
            var r = "" + num;
            while (r.length < length) {
                r = "0" + r;
            }
            return r;
        },

        searchVariationName(variation_id) {
            for (var i = 0; i < this.variations.length; i++) {
                for (var j = 0; i < this.variations[i].variations.length; j++) {
                    if (this.variations[i].variations[j].id == variation_id) {
                        return this.variations[i].variations[j].detail == null
                            ? ""
                            : this.variations[i].variations[j].detail[0].name;
                    }
                }
            }
        },

        searchBenefitName(benefit_id) {
            for (var i = 0; i < this.benefits.length; i++) {
                for (var j = 0; i < this.benefit[i].benefits.length; j++) {
                    if (this.benefits[i].benefits[j].id == benefit_id) {
                        return this.benefits[i].benefits[j].detail == null
                            ? ""
                            : this.benefits[i].benefits[j].detail[0].name;
                    }
                }
            }
        },

        cartesian(args) {
            var r = [],
                max = args.length - 1;
            function helper(arr, i) {
                for (var j = 0, l = args[i].length; j < l; j++) {
                    var a = arr.slice(0); // clone arr
                    a.push(args[i][j]);
                    if (i == max) r.push(a);
                    else helper(a, i + 1);
                }
            }
            helper([], 0);
            return r;
        },
        getVariation() {
            this.$parent.$parent.loading = true;
            var token = localStorage.getItem("token");
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            var responseData = {};

            if (this.selectedAttribute.indexOf(this.attribute) < 0) {
                this.selectedAttribute.push(this.attribute);
                this.setAttributes(this.attribute);
            } else {
                this.$parent.$parent.loading = false;
                return false;
            }
            var name = "variation_" + this.attribute;
            this.$set(this.variationData, name, []);

            axios
                .get(
                    "/api/admin/attribute/" +
                    this.attribute +
                    "?getVariation=1&getDetail=1",
                    config
                )
                .then((res) => {
                    if (res.data.status == "Success") {
                        this.variations.push(res.data.data);
                        // this.variations = res.data.data;
                    }
                })
                .finally(() => (this.$parent.$parent.loading = false));
        },

        getBenefit() {
            this.$parent.$parent.loading = true;
            var token = localStorage.getItem("token");
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            var responseData = {};

            if (this.selectedOpinion.indexOf(this.opinion) < 0) {
                this.selectedOpinion.push(this.opinion);
                this.setOpinions(this.opinion);
            } else {
                this.$parent.$parent.loading = false;
                return false;
            }
            var name = "benefit_" + this.opinion;
            this.$set(this.benefitData, name, []);

            axios
                .get(
                    "/api/admin/opinion/" +
                    this.opinion +
                    "?getBenefit=1&getDetail=1",
                    config
                )
                .then((res) => {
                    if (res.data.status == "Success") {
                        this.benefits.push(res.data.data);
                        console.error(res.data.data);
                        // this.variations = res.data.data;
                    }
                })
                .finally(() => (this.$parent.$parent.loading = false));
        },
        toggleImageSelect(name, index = null,type=null) {
            this.showModal = !this.showModal;
            this.currentSelectedGalleryName = name;
            this.currentSelectedGalleryIndex = index;
            this.currentSelectedGalleryType = type;
        },
        toggleIconSelect(name, index = null) {
            this.showIconModal = !this.showIconModal;
            this.currentSelectedIconName = name;
            this.currentSelectedIconIndex = index;
        },

        setImage(gallary) {



            // console.error(gallary);
            this.combinationGalleryPath[this.currentSelectedGalleryName] =
                gallary.gallary_path;

            this.combinationGallery[this.currentSelectedGalleryName] =
                gallary.gallary_id;

            this.setCombinationGallery(this.currentSelectedGalleryName);

           if(this.currentSelectedGalleryType=='gallary_description'){
            if(this.DataGallary_description.Gallary_description.length > this.currentSelectedGalleryIndex){
                this.DataGallary_description.Gallary_description[this.currentSelectedGalleryIndex]['image'] = gallary.gallary_path;

            }

        }

        if(this.currentSelectedGalleryType=='fullwidth'){
            if(this.full_width_gallary.length > this.currentSelectedGalleryIndex){
                this.full_width_gallary[this.currentSelectedGalleryIndex]['image'] = gallary.gallary_path;

            }

        }

        if(this.currentSelectedGalleryType=='fusion'){

            this.fusionsGalleryPath[this.currentSelectedGalleryName] = gallary.gallary_path;

            this.fusionGallery[this.currentSelectedGalleryName] = gallary.gallary_id;

            this.setFusionGallery(this.currentSelectedGalleryName);

            this.fusionsData[this.currentSelectedGalleryIndex]['gallary_id'] = gallary.gallary_id;
            console.error(this.fusionsData);
        }
        },

        setIcon(gallary) {
            // console.error(gallary);
            this.combinationGalleryPath[this.currentSelectedIconName] =
                gallary.gallary_path;



            this.combinationGallery[this.currentSelectedIconName] =
                gallary.gallary_id;

            this.setCombinationGallery(this.currentSelectedIconName);


          
            this.fusionsGalleryPath[this.currentSelectedIconName] = gallary.gallary_path;

            this.fusionGallery[this.currentSelectedIconName] = gallary.gallary_id;

            this.setFusionGallery(this.currentSelectedIconName);

            this.fusionsData[this.currentSelectedIconIndex]['icon_id'] = gallary.gallary_id;

            
            },
    showGallaryDescription() {
      this.$emit('showGallaryDescriptionInChild', this.DataGallary_description);
    },

    addRow(index) {
      this.DataGallary_description.Gallary_description.push({
        title: '',
        description: '',
        image: '',
        video: '',
        show_media: '',
        media_align: ''
      });
    },
    removeRow(index) {
      this.DataGallary_description.Gallary_description.splice(index, 1);
      this.showGallaryDescription();
    },
    FullWidthGallary() {

console.log(this.full_width_gallary.length)
console.log('==========================fullWidthGallaryAdvanceInfo')


this.$emit('FullWidthGallaryInChild', this.full_width_gallary);
},
    addRowFullWidth(index) {
      this.full_width_gallary.push({
        title: '',
        description: '',
        image: '',
        video: '',
        show_media: '',
      });
    },
    removeRowFullWidth(index) {
      this.full_width_gallary.splice(index, 1);
      this.FullWidthGallary();
    },



    },
    watch: {
        
    showGallaryDescriptionHandel: {
     // immediate: true, // Trigger the handler immediately when the component is created
      handler(newVal) {
  
       if(newVal==null){
        this.DataGallary_description = this.DataGallary_description

       }else{

      
        this.DataGallary_description = newVal

       }
        
      }
    },
    FullWidthGallaryHandel: {
        handler(newVal) {
  
       if(newVal==null){
        this.full_width_gallary = this.full_width_gallary
       }else{
        this.full_width_gallary = newVal
       }
        
      }

    },



        product(newVal, oldVal) {
            this.editChild = this.$parent.edit;
            this.product_type = newVal.product_type;
            this.sku = newVal.sku;

            // this.opinion = newVal.fusionData[0]?.benefit.opinion_id;
            // this.fusionsData = newVal.fusionData;
          
           
            this.product_status = newVal.product_status == "inactive" ? 0 : 1;
            this.is_featured =
                newVal.is_featured == true || newVal.is_featured == "true" ? 1 : 0;
            this.is_points =
                newVal.is_points == true || newVal.is_points == "true" ? 1 : 0;

                this.is_atimer =
                newVal.is_atimer == true || newVal.is_atimer == "true" ? 1 : 0;

                this.is_show_in_home =
                newVal.is_show_in_home == true || newVal.is_show_in_home == "true" ? 1 : 0;

                this.is_afifty_to_eighty =
                newVal.is_afifty_to_eighty == true || newVal.is_afifty_to_eighty == "true" ? 1 : 0;







            this.unit_id = newVal.unit_id;
            this.product_option = newVal.product_option;
            this.brand_id = newVal.brand_id;
            this.price = parseFloat(newVal.price);

            this.discount = newVal.discount;
            this.product_max_order = newVal.product_max_order;
            this.product_min_order = newVal.product_min_order;
            this.product_weight = newVal.product_weight;

            if (newVal.product_type == "variable") {
                this.edit_combination_detail = newVal.combination_detail;

                newVal.attributes.map((attribute_id, index) => {
                    this.attribute = attribute_id;
                    this.getVariation();

                    setTimeout(() => {
                        for (
                            var i = 0;
                            i < newVal.combination[attribute_id].variations.length;
                            i++
                        ) {
                            this.variationData["variation_" + attribute_id].push(
                                newVal.combination[attribute_id].variations[i].product_variation
                                    .id
                            );
                        }
                        this.setVariations("variation_" + attribute_id);
                    }, 5000);

                    // this.setVariations('variation_'+attribute_id);
                });
            }


            let benefitIds = newVal.fusionData
    .map((element) => element.benefit.id)  // Map to get all benefit ids
    .filter(id => id !== null);   
            console.log(benefitIds)
            this.benefitData["benefit"] = benefitIds;
 let fusionData = newVal.fusionData.filter(element => element.benefit.id !== null);

fusionData.sort((a, b) => {
    if (a.benefit.id !== null && b.benefit.id !== null) return a.benefit.id - b.benefit.id;
    if (a.benefit.id !== null) return -1;
    if (b.benefit.id !== null) return 1;
    return 0;
});





newVal.fusionData.forEach((element, index) => {
                if (element.opinion.show_benefit == 1) {
                    this.setBenefits('benefit', element.opinion.opinion_id, element.benefit.id, { 'score': element.score, 'note': element.note, 'description': element.description, 'heading1': element.heading1, 'heading2': element.heading2, 'body': element.body, 'benefit_id': element.benefit.id, 'opinion_id': element.opinion.opinion_id }, newVal.fusionData);
                   
                 if (element.gallary) {
                   
                        this.fusionsGalleryPath['fusion.gallary_'+ index] = '../../gallary/'+element.gallary.gallary_name;
                      
                }else{
                    this.fusionsGalleryPath['fusion.gallary_'+ index] = '';

                }


                if (element.icon) {
                   
                   this.fusionsGalleryPath['fusion.icon_' + index] = '../../gallary/'+element.icon.gallary_name;
                        
                }else{
                    this.fusionsGalleryPath['fusion.icon_' + index] = '';

                }


                }

                if (!this.benefits.some(el => el.opinion_id == element.opinion.opinion_id)) {
                if(element.editor_text != ''){
                    this.editorValues.push(element.editor_text);
                    this.benefits.push(element.opinion);
                   

                }

                    
                }

               

                // this.setImage(element.gallary)
            });
           
            
//show Gallary_description
if(this.DataGallary_description.Gallary_description[0]['title'] != ''){
    this.benefits.push({
            "opinion_id": null,
            "detail": null,
            "benefits": null,
            "show_benefit": null,
            "show_editor": null,
            "show_gallary_description": 1
        });
}

if(this.full_width_gallary[0]['title'] != ''){
    console.log("=====================================full_width_gallary")
        console.log(this.full_width_gallary.title)

    this.benefits.push({
            "opinion_id": null,
            "detail": null,
            "benefits": null,
            "show_benefit": null,
            "show_editor": null,
            "full_width_gallary": 1,
            "show_gallary_description": null
        });

        console.log(this.benefits)
}





        },
    },
   
    mounted() {
        console.log("===================3==================")

        console.log(this.DataGallary_description.Gallary_description)

        var token = localStorage.getItem("token");
        this.token = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        this.fetchUnits();
        this.fetchBrands();
        this.fetchAttributes();
        this.fetchOpinions();
        this.setFusion();

    },
    props: ["product", "errors", "edit","showGallaryDescriptionHandel","FullWidthGallaryHandel"],
};
</script>

<style scoped>
#parent {
    border: 0px solid #fff;
    /* Just for visualization */
}

#parent>div {
    width: 100%;
    /* Set the width of any direct child div to 100% of the parent div */
}
  .styled-table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #ddd;
  }

  .styled-table th, .styled-table td {
    padding: 8px;
    border: 1px solid #ddd;
  }

  .styled-table th {
    background-color: #f2f2f2;
  }

  .btn-add{
    background-color: #091bbb;
    color: white;
    border: none;
    padding: 4px 8px;
    margin: 1px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .btn-remove {
    background-color: #df0505;
    color: white;
    border: none;
    padding: 4px 8px;
    margin: 1px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  .btn-add:hover{
    background-color: #80a8d3;
  }
  .btn-remove:hover {
    background-color: #e49595;
  }
</style>
