var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "d-flex flex-column-fluid" }, [
    _c("div", { staticClass: "container-fluid" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12" }, [
              _c(
                "div",
                {
                  staticClass:
                    "card card-custom gutter-b bg-white border-0 h-auto",
                },
                [
                  _c("div", { staticClass: "card-body" }, [
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-12 col-md-6 d-flex align-items-center",
                        },
                        [
                          _c("div", { staticClass: "thumbnail-detail w-100" }, [
                            _c("img", {
                              staticClass: "img-fluid border-radius-10px w-100",
                              attrs: {
                                src: "/gallary/" + _vm.gallary.gallary_name,
                                alt: "actual image",
                              },
                            }),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-12 col-md-6" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "card card-custom bg-light shadow-none border-0",
                          },
                          [
                            _c("div", { staticClass: "card-body" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "thumbnail h-100 d-flex flex-column justify-content-center",
                                },
                                [
                                  _c("h4", { staticClass: "text-primary" }, [
                                    _vm._v(
                                      "Actual Size(\n                                                        " +
                                        _vm._s(
                                          _vm.gallary.detail
                                            ? _vm.gallary.detail[0]
                                                .gallary_type == "large"
                                              ? _vm.gallary.detail[0]
                                                  .gallary_height +
                                                "*" +
                                                _vm.gallary.detail[0]
                                                  .gallary_width
                                              : ""
                                            : ""
                                        ) +
                                        " \n                                                        \n                                                        )"
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "thumbnail-input-detail mt-3 w-100",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "form-group row align-items-center justify-content-center",
                                        },
                                        [
                                          _c("div", { staticClass: "col-12" }, [
                                            _c(
                                              "label",
                                              { staticClass: "form-label" },
                                              [_vm._v("Path")]
                                            ),
                                            _vm._v(" "),
                                            _c("input", {
                                              staticClass: "form-control",
                                              attrs: {
                                                type: "text",
                                                id: "first-name",
                                                name: "fname",
                                                placeholder: "image path",
                                              },
                                              domProps: {
                                                value:
                                                  "/gallary/" +
                                                  _vm.gallary.gallary_name,
                                              },
                                            }),
                                          ]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "form-group row align-items-center justify-content-center",
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "col-12" },
                                            [
                                              _c(
                                                "label",
                                                { staticClass: "form-label" },
                                                [_vm._v("Image Tag")]
                                              ),
                                              _vm._v(" "),
                                              _c("input-tag", {
                                                model: {
                                                  value: _vm.gallary_tags,
                                                  callback: function ($$v) {
                                                    _vm.gallary_tags = $$v
                                                  },
                                                  expression: "gallary_tags",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("br"),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "form-group row align-items-center justify-content-center",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "col-12 d-flex justify-content-end",
                                                    },
                                                    [
                                                      _c(
                                                        "button",
                                                        {
                                                          staticClass:
                                                            "btn btn-secondary white",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.updateTags()
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                                        Update Tags\n                                                                        "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "card card-custom gutter-b bg-white border-0 h-auto",
                },
                [
                  _c("div", { staticClass: "card-body" }, [
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-12 col-md-5 d-flex align-items-center",
                        },
                        [
                          _c("div", { staticClass: "thumbnail-detail w-100" }, [
                            _c("img", {
                              staticClass: "img-fluid border-radius-10px w-100",
                              attrs: {
                                src:
                                  _vm.gallary.detail[0].gallary_type == "large"
                                    ? "" + _vm.gallary.detail[0].gallary_path
                                    : "",
                                alt: "actual image",
                              },
                            }),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-12 col-md-7" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "card card-custom bg-light shadow-none border-0",
                          },
                          [
                            _c("div", { staticClass: "card-body" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "thumbnail h-100 d-flex flex-column justify-content-center",
                                },
                                [
                                  _c("h4", { staticClass: "text-primary" }, [
                                    _vm._v(
                                      "Large (" +
                                        _vm._s(
                                          _vm.gallary.detail
                                            ? _vm.gallary.detail[0]
                                                .gallary_type == "large"
                                              ? _vm.gallary.detail[0]
                                                  .gallary_height +
                                                "*" +
                                                _vm.gallary.detail[0]
                                                  .gallary_width
                                              : ""
                                            : ""
                                        ) +
                                        ")"
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "thumbnail-input-detail mt-3 w-100",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "form-group row align-items-center justify-content-center",
                                        },
                                        [
                                          _c("div", { staticClass: "col-12" }, [
                                            _c(
                                              "label",
                                              { staticClass: "form-label" },
                                              [_vm._v("Path")]
                                            ),
                                            _vm._v(" "),
                                            _c("input", {
                                              staticClass: "form-control",
                                              attrs: {
                                                type: "text",
                                                id: "first-name1",
                                                name: "fname",
                                                placeholder: "image path",
                                              },
                                              domProps: {
                                                value:
                                                  _vm.gallary.detail[0]
                                                    .gallary_type == "large"
                                                    ? "" +
                                                      _vm.gallary.detail[0]
                                                        .gallary_path
                                                    : "",
                                              },
                                            }),
                                          ]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "form-group row align-items-center justify-content-center",
                                        },
                                        [
                                          _c("div", { staticClass: "col-6" }, [
                                            _c(
                                              "label",
                                              { staticClass: "form-label" },
                                              [_vm._v("Height")]
                                            ),
                                            _vm._v(" "),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.resize_large.height,
                                                  expression:
                                                    "resize_large.height",
                                                },
                                              ],
                                              staticClass: "form-control",
                                              attrs: {
                                                type: "text",
                                                name: "fname",
                                                placeholder: "width",
                                              },
                                              domProps: {
                                                value: _vm.resize_large.height,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.$set(
                                                    _vm.resize_large,
                                                    "height",
                                                    $event.target.value
                                                  )
                                                },
                                              },
                                            }),
                                          ]),
                                          _vm._v(" "),
                                          _c("div", { staticClass: "col-6" }, [
                                            _c(
                                              "label",
                                              { staticClass: "form-label" },
                                              [_vm._v("Width")]
                                            ),
                                            _vm._v(" "),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.resize_large.width,
                                                  expression:
                                                    "resize_large.width",
                                                },
                                              ],
                                              staticClass: "form-control",
                                              attrs: {
                                                type: "text",
                                                name: "fname",
                                                placeholder: "Width",
                                              },
                                              domProps: {
                                                value: _vm.resize_large.width,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.$set(
                                                    _vm.resize_large,
                                                    "width",
                                                    $event.target.value
                                                  )
                                                },
                                              },
                                            }),
                                          ]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "form-group row align-items-center justify-content-center",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "col-12 d-flex justify-content-end",
                                            },
                                            [
                                              _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "btn btn-secondary white",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.resizeSingleImage(
                                                        _vm.gallary.detail[0]
                                                          .id,
                                                        "large"
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("Regenrate")]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "card card-custom gutter-b bg-white border-0 h-auto",
                },
                [
                  _c("div", { staticClass: "card-body" }, [
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-12 col-md-5 d-flex align-items-center",
                        },
                        [
                          _c("div", { staticClass: "thumbnail-detail w-100" }, [
                            _c("img", {
                              staticClass: "img-fluid border-radius-10px w-100",
                              attrs: {
                                src:
                                  _vm.gallary.detail[1].gallary_type == "medium"
                                    ? "" + _vm.gallary.detail[1].gallary_path
                                    : "",
                                alt: "actual image",
                              },
                            }),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-12 col-md-7" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "card card-custom bg-light shadow-none border-0",
                          },
                          [
                            _c("div", { staticClass: "card-body" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "thumbnail h-100 d-flex flex-column justify-content-center",
                                },
                                [
                                  _c("h4", { staticClass: "text-primary" }, [
                                    _vm._v(
                                      "MEDIUM (" +
                                        _vm._s(
                                          _vm.gallary.detail
                                            ? _vm.gallary.detail[1]
                                                .gallary_type == "medium"
                                              ? _vm.gallary.detail[1]
                                                  .gallary_height +
                                                "*" +
                                                _vm.gallary.detail[1]
                                                  .gallary_width
                                              : ""
                                            : ""
                                        ) +
                                        ")"
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "thumbnail-input-detail mt-3 w-100",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "form-group row align-items-center justify-content-center",
                                        },
                                        [
                                          _c("div", { staticClass: "col-12" }, [
                                            _c(
                                              "label",
                                              { staticClass: "form-label" },
                                              [_vm._v("Path")]
                                            ),
                                            _vm._v(" "),
                                            _c("input", {
                                              staticClass: "form-control",
                                              attrs: {
                                                type: "text",
                                                id: "first-name1",
                                                name: "fname",
                                                placeholder: "image path",
                                              },
                                              domProps: {
                                                value:
                                                  _vm.gallary.detail[1]
                                                    .gallary_type == "medium"
                                                    ? "" +
                                                      _vm.gallary.detail[1]
                                                        .gallary_path
                                                    : "",
                                              },
                                            }),
                                          ]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "form-group row align-items-center justify-content-center",
                                        },
                                        [
                                          _c("div", { staticClass: "col-6" }, [
                                            _c(
                                              "label",
                                              { staticClass: "form-label" },
                                              [_vm._v("Height")]
                                            ),
                                            _vm._v(" "),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.resize_medium.height,
                                                  expression:
                                                    "resize_medium.height",
                                                },
                                              ],
                                              staticClass: "form-control",
                                              attrs: {
                                                type: "text",
                                                name: "fname",
                                                placeholder: "width",
                                              },
                                              domProps: {
                                                value: _vm.resize_medium.height,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.$set(
                                                    _vm.resize_medium,
                                                    "height",
                                                    $event.target.value
                                                  )
                                                },
                                              },
                                            }),
                                          ]),
                                          _vm._v(" "),
                                          _c("div", { staticClass: "col-6" }, [
                                            _c(
                                              "label",
                                              { staticClass: "form-label" },
                                              [_vm._v("Width")]
                                            ),
                                            _vm._v(" "),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.resize_medium.width,
                                                  expression:
                                                    "resize_medium.width",
                                                },
                                              ],
                                              staticClass: "form-control",
                                              attrs: {
                                                type: "text",
                                                name: "fname",
                                                placeholder: "Width",
                                              },
                                              domProps: {
                                                value: _vm.resize_medium.width,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.$set(
                                                    _vm.resize_medium,
                                                    "width",
                                                    $event.target.value
                                                  )
                                                },
                                              },
                                            }),
                                          ]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "form-group row align-items-center justify-content-center",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "col-12 d-flex justify-content-end",
                                            },
                                            [
                                              _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "btn btn-secondary white",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.resizeSingleImage(
                                                        _vm.gallary.detail[1]
                                                          .id,
                                                        "medium"
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("Regenrate")]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "card card-custom gutter-b bg-white border-0 h-auto",
                },
                [
                  _c("div", { staticClass: "card-body" }, [
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-12 col-md-5 d-flex align-items-center",
                        },
                        [
                          _c("div", { staticClass: "thumbnail-detail w-100" }, [
                            _c("img", {
                              staticClass: "img-fluid border-radius-10px w-100",
                              attrs: {
                                src:
                                  _vm.gallary.detail[2].gallary_type ==
                                  "thumbnail"
                                    ? "" + _vm.gallary.detail[2].gallary_path
                                    : "",
                                alt: "actual image",
                              },
                            }),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-12 col-md-7" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "card card-custom bg-light shadow-none border-0",
                          },
                          [
                            _c("div", { staticClass: "card-body" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "thumbnail h-100 d-flex flex-column justify-content-center",
                                },
                                [
                                  _c("h4", { staticClass: "text-primary" }, [
                                    _vm._v(
                                      "Thumbnail (" +
                                        _vm._s(
                                          _vm.gallary.detail
                                            ? _vm.gallary.detail[2]
                                                .gallary_type == "thumbnail"
                                              ? _vm.gallary.detail[2]
                                                  .gallary_height +
                                                "*" +
                                                _vm.gallary.detail[2]
                                                  .gallary_width
                                              : ""
                                            : ""
                                        ) +
                                        ")"
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "thumbnail-input-detail mt-3 w-100",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "form-group row align-items-center justify-content-center",
                                        },
                                        [
                                          _c("div", { staticClass: "col-12" }, [
                                            _c(
                                              "label",
                                              { staticClass: "form-label" },
                                              [_vm._v("Path")]
                                            ),
                                            _vm._v(" "),
                                            _c("input", {
                                              staticClass: "form-control",
                                              attrs: {
                                                type: "text",
                                                id: "first-name1",
                                                name: "fname",
                                                placeholder: "image path",
                                              },
                                              domProps: {
                                                value:
                                                  _vm.gallary.detail[2]
                                                    .gallary_type == "thumbnail"
                                                    ? "" +
                                                      _vm.gallary.detail[2]
                                                        .gallary_path
                                                    : "",
                                              },
                                            }),
                                          ]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "form-group row align-items-center justify-content-center",
                                        },
                                        [
                                          _c("div", { staticClass: "col-6" }, [
                                            _c(
                                              "label",
                                              { staticClass: "form-label" },
                                              [_vm._v("Height")]
                                            ),
                                            _vm._v(" "),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.resize_thumbnail.height,
                                                  expression:
                                                    "resize_thumbnail.height",
                                                },
                                              ],
                                              staticClass: "form-control",
                                              attrs: {
                                                type: "text",
                                                name: "fname",
                                                placeholder: "width",
                                              },
                                              domProps: {
                                                value:
                                                  _vm.resize_thumbnail.height,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.$set(
                                                    _vm.resize_thumbnail,
                                                    "height",
                                                    $event.target.value
                                                  )
                                                },
                                              },
                                            }),
                                          ]),
                                          _vm._v(" "),
                                          _c("div", { staticClass: "col-6" }, [
                                            _c(
                                              "label",
                                              { staticClass: "form-label" },
                                              [_vm._v("Width")]
                                            ),
                                            _vm._v(" "),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.resize_thumbnail.width,
                                                  expression:
                                                    "resize_thumbnail.width",
                                                },
                                              ],
                                              staticClass: "form-control",
                                              attrs: {
                                                type: "text",
                                                name: "fname",
                                                placeholder: "height",
                                              },
                                              domProps: {
                                                value:
                                                  _vm.resize_thumbnail.width,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.$set(
                                                    _vm.resize_thumbnail,
                                                    "width",
                                                    $event.target.value
                                                  )
                                                },
                                              },
                                            }),
                                          ]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "form-group row align-items-center justify-content-center",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "col-12 d-flex justify-content-end",
                                            },
                                            [
                                              _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "btn btn-secondary white",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.resizeSingleImage(
                                                        _vm.gallary.detail[2]
                                                          .id,
                                                        "thumbnail"
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("Regenrate")]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                ]
              ),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-lg-12 col-xl-12" }, [
        _c(
          "div",
          {
            staticClass:
              "card card-custom gutter-b bg-transparent shadow-none border-0",
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "card-header align-items-center border-bottom-dark px-0",
              },
              [
                _c("div", { staticClass: "card-title mb-0" }, [
                  _c(
                    "h3",
                    {
                      staticClass: "card-label mb-0 font-weight-bold text-body",
                    },
                    [
                      _vm._v(
                        "Image Detail\n                                    "
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "icons d-flex" }),
              ]
            ),
          ]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }